<template>
  <!-- Header Start -->
  <header class="site-header">
        <div class="topbar for-des">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="topbar-wp">
                            <div class="social-icon-wp">
                                <ul>
                                    <li>
                                        <a href="https://api.whatsapp.com/send?phone=50939331465&text=Bonjour%20ZODE%20!%0d%0aJe%20te%20contacte%20depuis%20votre%20site%20web:%20https://www.zode-ht.com.&source=&data=#" title="Whatsapp">
                                            <i class="fab fa-whatsapp"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/ZodeDev" title="Facebook">
                                            <i class="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/zode-ht/about/" title="LinkedIn">
                                            <i class="fab fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="info-wp">
                                <ul>
                                    <li>
                                        <a href="tel:+50939331465" title="Appel +50939331465">
                                            <i class="fas fa-phone"></i> +50939331465
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:info@zode-ht.com" title="info@zode-ht.com">
                                            <i class="far fa-envelope"></i> info@zode-ht.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="site-branding">
                        <a href="#" class="custom-logo-link" title="ZODE">
                            <img src="assets/images/logo.png" alt="header-logo" width="166" height="50">
                        </a>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="header-main">
                        <nav class="main-navigation">
                            <div class="for-mob">
                                <button class="menu-toggle">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                            <div class="for-mob">
                                <a href="tel:+50939331465" title="Call +50939331465" class="mob-phone">
                                    <i class="fas fa-phone"></i>
                                </a>
                            </div>
                            <div class="header-menu">
                                <div class="menu-menu-1-container">
                                    <ul class="menu nav-menu">
                                        <li class="menu-item active"><a href="#" class="active" title="Home">Accueil</a></li>
                                        <li class="menu-item"><a href="#about" title="About Us">À Propos</a></li>
                                        <li class="menu-item"><a href="#produits" title="Services">Systèmes</a></li>
                                        <li class="menu-item"><a href="#services" title="Services">Services</a></li>
                                    </ul>
                                </div>
                                <div class="for-mob">
                                    <div class="social-wp">
                                        <div class="social-icon-wp">
                                            <ul>
                                                <li>
                                                    <a href="https://api.whatsapp.com/send?phone=50939331465&text=Bonjour%20ZODE%20!%0d%0aJe%20te%20contacte%20depuis%20votre%20site%20web:%20https://www.zode-ht.com.&source=&data=#" title="Whatsapp">
                                                        <i class="fab fa-whatsapp"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.facebook.com/ZodeDev" title="Facebook">
                                                        <i class="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/zode-ht/about/" title="LinkedIn">
                                                        <i class="fab fa-linkedin-in"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="info-wp">
                                            <ul>
                                                <li>
                                                    <a href="tel:+50939331465" title="Call +50939331465">
                                                        <span><i class="fas fa-phone"></i></span> +50939331465
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="mailto:info@zode-ht.com" title="info@zode-ht.com">
                                                        <span><i class="far fa-envelope"></i></span> info@zode-ht.com
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="mob-triangle-shep"></div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- Header End -->

    <div class="content-area">
        <main class="main">
            <!-- Main Banner -->
            <section class="main-banner" style="background-image: url('assets/images/banner.png');" id="main-banner">
                <div class="banner-shep"></div>
                <div class="sec-wp">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="banner-tet-wp wow animate__ animate__fadeInLeft animated" data-wow-duration="1.4s">
                                    <h3 class="h3-title">Uniquement de la haute qualité</h3>
                                    <h1 class="h1-title">Externalisation de solution informatique</h1>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End Main Banner -->

            <!-- About Us -->
            <section id="about" class="about-us-sec mb-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 wow animate__ animate__fadeInLeft animated" data-wow-duration="1.4s" data-wow-delay="0.1s">
                            <div class="aboutus-img-wp">
                                <div class="about-us-img" style="background-image: url(/assets/images/about.png);"></div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 wow animate__ animate__fadeInRight animated" data-wow-duration="1.4s" data-wow-delay="0.1s">
                            <div class="about-us-text-wp">
                                <h2 class="h2-title">À Propos</h2>
                                <h3 class="h3-title">Des solutions informatiques universelles, adaptées à vos besoins</h3>
                                <div class="about-us-text">
                                    <p>
                                        Chez ZODE, notre préoccupation est de repousser les limites de l'ingénierie des systèmes d'information. 
                                        Nous nous engageons à fournir des services exceptionnels en offrant des solutions technologiques innovantes pour les applications web, mobiles et desktop, tout en respectant des normes de qualité strictes. 
                                        <br>Faites confiance à ZODE pour une collaboration solide et des résultats exceptionnels à chaque étape.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End About Us -->

            <!-- Services -->
            <section>
                <div class="container">
                    <div class="row" id="services">
                        <div class="col-lg-12 text-lg-center">
                            <div class="title">
                                <h2 class="h2-title wow animate__ animate__fadeInUp animated">
                                    Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="sec-wp wow animate__ animate__fadeInUp animated" data-wow-duration="2s">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="service-box-wp">
                                    <div class="services-img-wp" style="background-image: url('/assets/images/services-img-1.jpg');"></div>
                                    <div class="services-text-wp">
                                        <h4 class="h5-title">Développement IT</h4>
                                        <div class="services-text">
                                            <p>
                                                Développement d'applications WEB, MOBILE, DESKTOP sur mesure.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="service-box-wp">
                                    <div class="services-img-wp" style="background-image: url('/assets/images/infra.png');"></div>
                                    <div class="services-text-wp">
                                        <h4 class="h5-title">Infrastructure système</h4>
                                        <div class="services-text">
                                            <p>
                                                ZIS, c’est un service offert par ZODE pour la mise en place et la gestion des infrastructures informatiques 
                                                de n’importe quelle entreprise.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="service-box-wp">
                                    <div class="services-img-wp" style="background-image: url('assets/images/consultation.png');"></div>
                                    <div class="services-text-wp">
                                        <h4 class="h5-title">Consultation IT</h4>
                                        <div class="services-text">
                                            <p>
                                                Notre service de consultation en informatique offre des solutions personnalisées pour répondre aux besoins uniques de votre entreprise.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- End Services -->

            <!-- Produits -->
            <section class="solution-sec">
                <div class="sec-wp">
                        <div class="container" id="produits">
                            <div class="row">
                                <div class="col-lg-12 text-lg-center">
                                    <div class="title">
                                        <h2 class="h2-title wow animate__ animate__fadeInUp animated" data-wow-duration="1.8s">Systèmes</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <div class="tab-content">
                                    <div id="tab1" class="tab-pane fade show active wow animate__ animate__fadeInUp animated" data-wow-duration="2.2s">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-6">
                                                <div class="solution-img-wp">
                                                    <div class="solution-img" style="background-image: url('/assets/images/sglz.png');"></div>
                                                    <div class="overlay-text">
                                                        <p href="#produits" class="add" title="Voir">
                                                            <i class="fas fa-eye" data-toggle="modal" data-target="#myModalSGLZ"></i>
                                                        </p>
                                                        <div class="author">
                                                            <h5>SGLZ</h5>
                                                            <span>Système Gestion Loterie ZODE</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-6">
                                                <div class="solution-img-wp">
                                                    <div class="solution-img" style="background-image: url('/assets/images/sloz.png');"></div>
                                                    <div class="overlay-text">
                                                        <a href="javascript:void(0)" class="add" title="Voir">
                                                            <i class="fas fa-eye" data-toggle="modal" data-target="#myModalSLOZ"></i>
                                                        </a>
                                                        <div class="author">
                                                            <h5>SLOZ</h5>
                                                            <span>Système Loterie Online ZODE</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-6">
                                                <div class="solution-img-wp">
                                                    <div class="solution-img" style="background-image: url('/assets/images/zgh.png');"></div>
                                                    <div class="overlay-text">
                                                        <a href="javascript:void(0)" class="add" title="Voir">
                                                            <i class="fas fa-eye" data-toggle="modal" data-target="#myModalZGH"></i>
                                                        </a>
                                                        <div class="author">
                                                            <h5>ZGH</h5>
                                                            <span>Zode Gestion Hopital</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-6">
                                                <div class="solution-img-wp">
                                                    <div class="solution-img" style="background-image: url('assets/images/zgc.png');"></div>
                                                    <div class="overlay-text">
                                                        <a href="javascript:void(0)" class="add" title="Add">
                                                            <i class="fas fa-eye" data-toggle="modal" data-target="#myModalZGC"></i>
                                                        </a>
                                                        <div class="author">
                                                            <h5>ZGC</h5>
                                                            <span>Zode Gestion Caisse</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            <!-- </div> -->
            </section>
            <!-- End Produits -->
    </main>
    </div>

    <footer class="site-footer">
        <div class="sec-wp">
            <div class="top-footer">
                <div class="container">
                    <div class="top-footer-wp">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="footer-text-wp">
                                    <div class="footer-logo">
                                        <a href="javascript:void(0)" title="ZODE">
                                            <img src="assets/images/logo.png" alt="footer-logo" width="200" height="60">
                                        </a>
                                    </div>
                                    <div class="footer-text">
                                        <p>Des solutions informatiques universelles, adaptées à vos besoins.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="footer-navigation">
                                    <h3>Navigation</h3>
                                    <ul>
                                        <li>
                                            <a href="#" title="Accueil" class="active">Accueil</a>
                                        </li>
                                        <li>
                                            <a href="#about" title="À Propos">À Propos</a>
                                        </li>
                                        <li>
                                            <a href="#produits" title="Produits">Systèmes</a>
                                        </li>
                                        <li>
                                            <a href="#services" title="Services">Services</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="contact-info-wp">
                                    <h3>Info Contact</h3>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0)" title="177, rue Faubert, Petion-ville , Haiti" class="address-wp">
                                                <span class="contact-icon address-icon">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                </span>
                                                <span class="contact-info address-info">
                                                    177, rue Faubert, Petion-ville , Haiti &nbsp;
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:info@zode-ht.com" title="info@zode-ht.com">
                                                <span class="contact-icon">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="contact-info">
                                                    info@zode-ht.com
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:+50939331465" title="+50939331465">
                                                <span class="contact-icon">
                                                    <i class="fas fa-phone"></i>
                                                </span>
                                                <span class="contact-info">
                                                    +50939331465
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://api.whatsapp.com/send?phone=50939331465&text=Bonjour%20ZODE%20!%0d%0aJe%20te%20contacte%20depuis%20votre%20site%20web:%20https://www.zode-ht.com.&source=&data=#" title="+50939331465">
                                                <span class="contact-icon">
                                                    <i class="fab fa-whatsapp"></i>
                                                </span>
                                                <span class="contact-info">
                                                    +50939331465
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="copyright-wp">
                                <div class="copyright-link">
                                    <ul>
                                        <li>
                                            &copy; Copyright 2024 - ZODE | Tous droits réservés
                                        </li>
                                        <!-- <li>
                                            <a href="javascript:void()" title="Privacy Policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void()" title="Terms Of Use">Terms Of Use</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void()" title="Cookie Policy">Cookie Policy</a>
                                        </li> -->
                                    </ul>
                                </div>
                                <div class="copyright-text">
                                    <div class="social-icon-wp">
                                        <ul>
                                            <li>
                                                <a href="https://api.whatsapp.com/send?phone=50939331465&text=Bonjour%20ZODE%20!%0d%0aJe%20te%20contacte%20depuis%20votre%20site%20web:%20https://www.zode-ht.com.&source=&data=#" title="Whatsapp">
                                                    <i class="fab fa-whatsapp"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.facebook.com/ZodeDev" title="Facebook">
                                                    <i class="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/zode-ht/about/" title="LinkedIn">
                                                    <i class="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

    <!-- Scroll To Top Start -->
    <a href="#" id="scrollToTop" class="scrolltop" title="Back To Top" style="display: none;"><i
            class="fas fa-arrow-up"></i></a>
    <!-- Scroll To Top End -->
        <!--Start Modal SGLZ -->
            <!-- The Modal -->
            <div class="modal fade in" id="myModalSGLZ">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <!-- Modal Header -->
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h1>#SGLZ</h1>
                        </div>                        
                        <!-- Modal body -->
                        <div class="modal-body container benbgcolor">
                            <p>C’est un système d’informatique qui permet la gestion des ventes de loterie sur les derniers outils technologiques, où vous pouvez vendre et contrôler rapidement en temps réel.</p>
                            <p>SGLZ est conçu pour vous aider à mieux gérer votre entreprise de loterie. Il est livré avec deux (2) applications :</p>
                            <ol class="p-3">
                                <li>Une pour la vente de la loterie, une application installable sur les POS (SGLZ POS)</li>
                                <li>Une pour le contrôle administratif de toutes les banques et de toutes les ventes (SGLZ Admin)</li>
                            </ol>
                        </div>                        
                        <!-- Modal footer -->
                        <div class="modal-footer">
                        <button type="button" class="btn btn-info benbgcolor" data-dismiss="modal">Close</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        <!--End Modal SGLZ -->

        <!--Start Modal SLOZ -->
            <!-- The Modal -->
            <div class="modal" id="myModalSLOZ">
                <div class="modal-dialog modal-lg modal-dialog-centered">                    
                    <div class="modal-content">  
                            <!-- Modal Header -->
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h1>#SLOZ</h1>
                            </div>                        
                            <!-- Modal body -->
                            <div class="modal-body container benbgcolor">
                                <p>
                                    Le système a pour but de gérer la vente de loterie en ligne, 
                                    en permettant au client d’avoir un espace privé pour acheter en toute sécurité via leurs comptes avec les différents moyens de paiement électronique possibles sur le marché haïtien,
                                    de faire les différents types de transactions à savoir dépôt, retrait sur leurs comptes.
                                </p>
                                <p>
                                    Le système doit offrir une accessibilité aux clients via diverses plateformes technologiques telles que les téléphones, les tablettes et les ordinateurs. 
                                    De plus, il doit être capable de prendre en charge différents types de caisses de la centrale, notamment les grandes caisses et les petites caisses.
                                </p>
                            </div>                         
                                            
                        <!-- Modal footer -->
                        <div class="modal-footer">
                        <button type="button" class="btn btn-info benbgcolor" data-dismiss="modal">Close</button>
                        </div>  
                    </div>                  
                </div>
            </div>
        <!--End Modal SLOZ -->

        <!--Start Modal ZGH -->
            <!-- The Modal -->
            <div class="modal" id="myModalZGH">
                <div class="modal-dialog modal-lg modal-dialog-centered">                    
                    <div class="modal-content">  
                            <!-- Modal Header -->
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h1>#ZGH</h1>
                            </div>                        
                            <!-- Modal body -->
                            <div class="modal-body container benbgcolor">
                                <p>
                                    C'est un système de gestion hospitalière complet conçu pour améliorer l'efficacité et la coordination au sein des établissements de santé. 
                                    Doté de fonctionnalités avancées telles que la gestion des patients, la gestion des ressources, la gestion des dossiers médicaux électroniques et la facturation, ZGH offre une solution intégrée pour répondre aux besoins complexes des hôpitaux modernes. 
                                </p>
                                <p>
                                    En centralisant les données et les processus, ZGH permet aux équipes médicales de travailler de manière plus collaborative, favorisant ainsi une meilleure prise en charge des patients et une optimisation des ressources hospitalières. 
                                    Grâce à son interface conviviale et ses outils analytiques avancés, ZGH aide les établissements de santé à améliorer leurs performances opérationnelles et à offrir des soins de qualité supérieure à leurs patients.

                                </p>
                            </div>                         
                                            
                        <!-- Modal footer -->
                        <div class="modal-footer">
                        <button type="button" class="btn btn-info benbgcolor" data-dismiss="modal">Close</button>
                        </div>  
                    </div>                  
                </div>
            </div>
        <!--End Modal ZGH -->
        
        <!--Start Modal ZGC -->
            <!-- The Modal -->
            <div class="modal" id="myModalZGC">
                <div class="modal-dialog modal-lg modal-dialog-centered">                    
                    <div class="modal-content">  
                            <!-- Modal Header -->
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <h1>#ZGC</h1>
                            </div>                        
                            <!-- Modal body -->
                            <div class="modal-body container benbgcolor">
                                <p>
                                    C'est un système conçue pour faciliter et automatiser les processus de transaction et de gestion financière dans divers environnements commerciaux. 
                                    Il permet aux entreprises de traiter les ventes, d'accepter différents modes de paiement, de calculer les taxes et de générer des reçus pour les clients. 
                                    En plus de cela, ces systèmes fournissent des fonctionnalités de gestion des stocks, de suivi des ventes, et de génération de rapports pour aider les entreprises à prendre des décisions informées sur leur activité.
                                </p>
                                <p>
                                    En intégrant un système de gestion de caisse, les entreprises peuvent améliorer leur efficacité opérationnelle, réduire les erreurs de transaction et suivre plus précisément leurs performances financières. 
                                    Ces systèmes offrent également une plus grande transparence dans les opérations de caisse, réduisant ainsi les risques de fraude ou de mauvaise gestion. En fin de compte, un système de gestion de caisse bien mis en place contribue à une expérience client améliorée et à une gestion financière plus efficace pour l'entreprise.
                                </p>
                                <p class="text-center">
                                    <button class="btnben">
                                        <a class="text-center text-uppercase" target="_blanc" href="http://zgc.zode-app.com/">Connecter à ZGC</a>
                                    </button>
                                </p>


                            </div>                         
                                            
                        <!-- Modal footer -->
                        <div class="modal-footer">
                        <button type="button" class="btn btn-info benbgcolor" data-dismiss="modal">Close</button>
                        </div>  
                    </div>                  
                </div>
            </div>
        <!--End Modal ZGC -->


</template>

<script>
export default{
  name:'HomeView'
}
</script>
<style>
.benbgcolor{
    background-color: #43BAFF;
    color:#FFF;
}
.btnben {
  background-color: #FFF;
  border: none;
  color: #43BAFF;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
}

button.btnben:hover {
  background-color: #43BAFF;
  color: white;
  text-decoration: underline;
}

p .btnben a:hover{
    color:#FFF;
}
</style>
